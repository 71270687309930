import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  Article,
  Column3,
  ContainerCol2,
  Sidebar,
  Thumbnail,
  Title,
} from "../utils"
import Tags from "../components/tags"

export const pageQuery = graphql`
  query ($name: String) {
    allContentfulPosts(
      filter: { tags: { elemMatch: { name: { eq: $name } } } }
    ) {
      edges {
        node {
          tags {
            name
            tagSlug
          }
          slug
          title
          thumbnail {
            gatsbyImageData
          }
        }
      }
    }
  }
`

const Tag = ({ data }) => {
  const tags = data.allContentfulPosts.edges
  return (
    <ContainerCol2>
      <Column3>
        {tags &&
          tags.map(({ node: tag }) => {
            return (
              <Article>
                <Link to={`/blog/${tag.slug}`}>
                  <Thumbnail>
                    <GatsbyImage image={getImage(tag.thumbnail)} />
                  </Thumbnail>
                  <Title>{tag.title}</Title>
                </Link>
              </Article>
            )
          })}
      </Column3>
      <Sidebar>
        <Tags />
      </Sidebar>
    </ContainerCol2>
  )
}

export default Tag
